import { ChartBody, ChartContainer, ChartHeader } from "../ChartLayout";
import { Box } from "@mui/material";
import LoadingScreen from "../../../../sections/reports/shared/LoadingScreen";

export function ChartLoadingFrame({
  size,
  sx = {},
  variant = "default",
}: {
  size: "lg" | "sm";
  sx?: any;
  variant?: "default" | "shimmer";
}) {
  return (
    <ChartContainer
      id="chart-container"
      size={size}
      sx={{
        ...sx,
        position: "relative",
      }}
    >
      <ChartHeader title="" subTitle="" />
      <ChartBody size={size} useTimestamp={false}>
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        >
          {variant === "shimmer" ? (
            <Box className="loading-box" />
          ) : (
            <LoadingScreen useTypingEffect={false} />
          )}
        </Box>
      </ChartBody>
    </ChartContainer>
  );
}
