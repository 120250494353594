import {
  Box,
  Container,
  ContainerProps,
  Typography,
  TypographyProps,
  styled,
  alpha,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactElement } from "react";
import DateTimeHelper from "../../helpers/datetime.helper";
import { ReactComponent as TwelveLogo } from "../../../shared/icons/twelve-logo.svg";

export interface BaseChartProps extends ContainerProps {
  size?: "lg" | "sm";
  useTransparentBackground?: boolean;
}

interface ChartContainerProps extends BaseChartProps {
  children?: ReactElement | Array<ReactElement>;
  width?: number | string;
  height?: number | string;
  sx?: any;
  useTimestamp?: boolean;
}

interface ChartHeaderProps extends BaseChartProps {
  title: string;
  subTitle?: string;
}

interface ChartFooterProps extends ChartContainerProps {
  height?: number;
  children: ReactElement | Array<ReactElement>;
}

interface StyledTypographyProps extends TypographyProps {
  size?: "lg" | "sm";
}

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== "colors",
})<ChartContainerProps>(({ theme, size = "lg" }) => {
  const smStyle = {
    background: "unset",
    border: "unset",
    maxWidth: "360px!important",
    padding: "0!important",
    width: "100%",
    height: "unset",
  };

  return {
    position: "relative",
    width: "540px",
    height: "600px",
    fontFamily: theme.typography.fontFamily,
    background: `linear-gradient(
            139deg,
            ${theme.palette.customColors.visualisation.background1} 0%,
            ${alpha(
              theme.palette.customColors.visualisation.background1,
              0.95,
            )} 100%),
            ${theme.palette.customColors.section.gradient}
        `,
    borderRadius: "8px",
    padding: "24px 32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "8px",
    ...(size === "sm" ? smStyle : {}),
    [theme.breakpoints.down("sm")]: size !== "sm" && {
      ...smStyle,
      maxHeight: "unset!important",
    },
    [theme.breakpoints.down("md")]: size !== "sm" && {
      maxWidth: "360px",
      maxHeight: "436px",
    },
  };
});

const StyledHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "colors",
})<ChartContainerProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {},
}));

const StyledBody = styled("div", {
  shouldForwardProp: (prop) => prop !== "colors",
})<ChartContainerProps>(({ theme, size = "lg" }) => {
  const smStyle: any = {
    borderRadius: "8px",
    background: `linear-gradient(
            139deg,
            ${theme.palette.customColors.visualisation.background1} 0%,
            ${alpha(
              theme.palette.customColors.visualisation.background1,
              0.95,
            )} 100%),
            ${theme.palette.customColors.section.gradient}
        `,
    display: "flex",
    flexDirection: "column",
    minHeight: "320px",
    justifyContent: "center",
  };

  return {
    display: "contents",
    position: "relative",
    ...(size === "sm" ? smStyle : {}),
    [theme.breakpoints.down("sm")]: size !== "sm" && smStyle,
  };
});

export const StyledBodyText = styled("div", {
  shouldForwardProp: (prop) => prop !== "colors",
})<ChartContainerProps>(({ theme, size = "lg" }) => {
  const smStyle: any = {
    fontSize: "12px",
  };

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: alpha(
      theme.palette.customColors.text,
      theme.palette.customColors.opacity.text.disabled,
    ),
    fontWeight: 200,
    fontSize: "12px",
    minHeight: "32px",
    ...(size === "sm" ? { ...smStyle, alignItems: "flex-start" } : {}),
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: size !== "sm" && smStyle,
  };
});

const StyledFooter = styled(Box, {
  shouldForwardProp: (prop) => prop !== "colors",
})<ChartContainerProps>(({ theme, size = "lg" }) => {
  const smStyle = {
    borderRadius: "8px",
    background: `linear-gradient(
            139deg,
            ${theme.palette.customColors.visualisation.background1} 0%,
            ${alpha(
              theme.palette.customColors.visualisation.background1,
              0.95,
            )} 100%),
            ${theme.palette.customColors.section.gradient}
        `,
    paddingTop: "0",
    "&::before": {
      display: "none",
    },
  };

  return {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: `${theme.palette.customColors.text}F2`,
    fontWeight: 200,
    paddingTop: "20px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "1px",
      background: `linear-gradient(
                90deg,
                ${alpha(theme.palette.customColors.section.gradient, 0.05)} 0%,
                ${alpha(theme.palette.customColors.section.gradient, 0.15)} 50%,
                ${alpha(
                  theme.palette.customColors.section.gradient,
                  0.05,
                )} 100%)
            `,
    },
    ...(size === "sm" ? smStyle : {}),
    [theme.breakpoints.down("sm")]: size !== "sm" && smStyle,
  };
});

const StyledTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "colors",
})<StyledTypographyProps>(({ theme, size = "lg" }) => {
  const smStyle = {
    fontSize: "16px",
  };

  return {
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0.15px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    ...(size === "sm"
      ? {
          ...smStyle,
          color: alpha(
            theme.palette.text.primary,
            theme.palette.customColors.opacity.text.primary,
          ),
        }
      : {
          fontSize: "18px",
          color: alpha(
            theme.palette.customColors.text,
            theme.palette.customColors.opacity.text.primary,
          ),
        }),
    [theme.breakpoints.down("sm")]: size !== "sm" && smStyle,
  };
});

const StyledSubTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "colors",
})<StyledTypographyProps>(({ theme, size = "lg" }) => {
  const smStyle = {
    fontSize: "12px",
  };

  return {
    fontWeight: 600,
    ...(size === "sm"
      ? {
          ...smStyle,
          color: alpha(
            theme.palette.text.primary,
            theme.palette.customColors.opacity.text.secondary,
          ),
        }
      : {
          fontSize: "16px",
          color: alpha(
            theme.palette.customColors.text,
            theme.palette.customColors.opacity.text.secondary,
          ),
        }),
    [theme.breakpoints.down("sm")]: size !== "sm" && smStyle,
  };
});

export function ChartHeader({ title, subTitle, size }: ChartHeaderProps) {
  return (
    <StyledHeader size={size}>
      <StyledTitle variant="caption" size={size}>
        {title}
      </StyledTitle>
      <StyledSubTitle variant="caption" size={size}>
        {subTitle}
      </StyledSubTitle>
    </StyledHeader>
  );
}

export function ChartFooter({
  children,
  size,
  height = 64,
  sx = {},
}: ChartFooterProps) {
  return (
    <StyledFooter sx={{ height: `${height}px`, ...sx }} size={size}>
      {children}
    </StyledFooter>
  );
}

export function ChartBody({
  children,
  size,
  sx = {},
  useTimestamp = true,
  useTransparentBackground = false,
}: ChartContainerProps) {
  const theme = useTheme();
  if (useTransparentBackground === true) {
    sx.background = "transparent";
  }
  return (
    <StyledBody size={size} sx={{ ...sx }}>
      <>
        {children}
        {useTimestamp && (
          <>
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                left: 10,
                bottom: 12,
                fontSize: "7px",
                color: alpha(
                  theme.palette.customColors.text,
                  theme.palette.customColors.opacity.text.disabled,
                ),
                [theme.breakpoints.up("md")]: size !== "sm" && {
                  left: 24,
                  bottom: 12,
                },
              }}
            >
              {DateTimeHelper.visualTimestamp()}
            </Typography>
            <Box
              sx={{
                position: "absolute",
                right: 10,
                bottom: 10,
                opacity: 0.5,
                [theme.breakpoints.up("md")]: size !== "sm" && {
                  right: 24,
                  bottom: 10,
                },
              }}
            >
              <TwelveLogo />
            </Box>
          </>
        )}
      </>
    </StyledBody>
  );
}

export function ChartContainer({
  children,
  size,
  sx = {},
}: ChartContainerProps) {
  return (
    <StyledContainer sx={{ ...sx }} size={size}>
      {children}
    </StyledContainer>
  );
}

type PitchChartWrapperProps = {
  labels?: Array<string>;
  footerText?: string;
  children?: ReactElement;
  orientation?: "horizontal" | "vertical";
  childrenOverPitch?: boolean;
  backgroundLikeGrid?: boolean;
};

export function PitchChartWrapper({
  labels,
  children,
  orientation,
  childrenOverPitch,
  backgroundLikeGrid = true,
}: PitchChartWrapperProps) {
  const theme = useTheme();
  const strokeWidth = 0.3;
  const strokeColor = "#86938E";
  const isVertical = orientation === "vertical";
  const width: number = 490;
  const height: number = 365;
  const padding = {
    top: 0,
    bottom: 24,
    left: 0,
    right: 0,
  };
  const plotPadding = 10;
  const footerHeight: number = 0;
  const x = (value: number) => 1.05 * value;
  const y = (value: number) => 0.68 * (100 - value);

  const w = isVertical ? height / 1.85 : width;
  const h = isVertical ? width / 2.5 : height;

  const style: React.CSSProperties = {};
  if (isVertical) {
    style.height = "100%";
  } else {
    style.width = "100%";
  }

  return (
    <svg
      viewBox={`0 0 ${w} ${h}`}
      style={{
        ...style,
      }}
    >
      <g transform={isVertical ? `rotate(90 ${w / 2} ${h / 2})` : ``}>
        <svg viewBox={`0 0 ${width} ${height}`}>
          <g>
            <svg viewBox={`0 0 ${105 + 2 * 3} ${68 + 2 * 1}`}>
              <rect width={105} height={68} fill={"#002C1C"} x={3} y={1} />
              <g transform={`translate(3,1)`}>
                {backgroundLikeGrid &&
                  Array.from(Array(10).keys()).map((_, index) => {
                    const w = 105 / 10;
                    return (
                      <rect
                        key={`field-${index}`}
                        width={w + 0.1}
                        height={68}
                        fill={index % 2 ? "#91D087" : "#82C577"}
                        x={w * index}
                      />
                    );
                  })}
                {childrenOverPitch !== true && children}
                {/* Pitch bounds */}
                <path
                  d="M 0 0 H 105 V 68 H 0 Z"
                  fill="none"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  strokeLinejoin="round"
                />
                {/* Halfway line */}
                <line
                  x1={x(50)}
                  y1={y(0)}
                  x2={x(50)}
                  y2={y(100)}
                  fill="none"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                />
                {/* Center circle */}
                <circle
                  cx={x(50)}
                  cy={y(50)}
                  r={9.15}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  fill="none"
                />
                {/* Center spot */}
                <circle
                  cx={x(50)}
                  cy={y(50)}
                  r={strokeWidth}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  fill="none"
                />
                {/* Left penalty area */}
                <rect
                  x={x(0)}
                  y={y(81)}
                  width={x(16)}
                  height={y(19) - y(81)}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  strokeLinejoin="round"
                  fill="none"
                />
                {/* Left goal area */}
                <rect
                  x={x(0)}
                  y={y(62)}
                  width={x(6)}
                  height={y(38) - y(62)}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  strokeLinejoin="round"
                  fill="none"
                />
                <rect
                  x={-2}
                  y={34 - 7.32 / 2}
                  width={2}
                  height={7.32}
                  fill={strokeColor}
                  strokeLinejoin="round"
                  fillOpacity={0.5}
                />
                {/* Left penalty spot */}
                <circle
                  cx={x(11)}
                  cy={y(50)}
                  r={strokeWidth}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  fill={strokeColor}
                />
                {/* Right penalty area */}
                <rect
                  x={x(84)}
                  y={y(81)}
                  width={x(16)}
                  height={y(19) - y(81)}
                  stroke={strokeColor}
                  strokeLinejoin="round"
                  strokeWidth={strokeWidth}
                  fill="none"
                />
                {/* Right goal area */}
                <rect
                  x={x(94)}
                  y={y(62)}
                  width={x(6)}
                  height={y(38) - y(62)}
                  stroke={strokeColor}
                  strokeLinejoin="round"
                  strokeWidth={strokeWidth}
                  fill="none"
                />
                <rect
                  x={105}
                  y={34 - 7.32 / 2}
                  width={2}
                  height={7.32}
                  fill={strokeColor}
                  strokeLinejoin="round"
                  fillOpacity={0.5}
                />
                {/* Right penalty spot */}
                <circle
                  cx={x(89)}
                  cy={y(50)}
                  r={strokeWidth}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  fill={strokeColor}
                />
                {/* Left penalty arc */}
                <path
                  d="M5.499,-7.313A9.15,9.15,0,0,1,5.499,7.313A9.15,9.15,0,0,0,5.499,-7.313Z"
                  transform="translate(11.235,34) rotate(0)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
                {/* Right penalty arc */}
                <path
                  d="M5.499,-7.313A9.15,9.15,0,0,1,5.499,7.313A9.15,9.15,0,0,0,5.499,-7.313Z"
                  transform="translate(93.765,34) rotate(180)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
                {/* Corner arcs */}
                <path
                  d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z"
                  transform="translate(0,68) rotate(0)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
                <path
                  d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z"
                  transform="translate(0,0) rotate(90)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
                <path
                  d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z"
                  transform="translate(105,0) rotate(180)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
                <path
                  d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z"
                  transform="translate(105,68) rotate(279)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
                {childrenOverPitch === true && children}
              </g>
            </svg>
          </g>
          {labels && (
            <g>
              {labels.map((label, index) => {
                const labelsCount = labels?.length || 1;
                return (
                  <text
                    key={`label-${index}`}
                    x={
                      plotPadding +
                      ((index + 0.5) * (width - 2 * plotPadding)) / labelsCount
                    }
                    y={height - padding.bottom - footerHeight + 15}
                    fill={`${theme.palette.customColors.text}A6`}
                    textAnchor="middle"
                    fontSize={"14px"}
                    fontWeight={200}
                    opacity={0.5}
                  >
                    {label}
                  </text>
                );
              })}
            </g>
          )}
        </svg>
      </g>
    </svg>
  );
}

type HalfPitchChartWrapperProps = {
  footerText?: string;
  children?: ReactElement;
  size?: "lg" | "sm";
};

export function HalfPitchChartWrapper({
  children,
  size,
}: HalfPitchChartWrapperProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const strokeWidth = 0.3;
  const strokeColor = "#86938E";
  const height: number = 68;
  const width: number = 52.5;
  const x = (value: number) => 1.05 * value;
  const y = (value: number) => 0.68 * (100 - value);

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      style={{
        width: "100%",
      }}
      transform={`rotate(-90) scale(${size === "sm" || isMobile ? 1 : 1.1})`}
    >
      <g>
        <svg viewBox={`0 0 ${width} ${height}`}>
          <g>
            <svg viewBox={`-0.05 -0.05 ${52.5 + 0.4} ${68 + 0.4}`}>
              <rect width={52.5} height={68} fill={"#002C1C"} x={0.1} y={0.1} />
              <g transform={`translate(0.1,0.1)`}>
                {children}
                {/* Pitch bounds */}
                <path
                  d="M 0 0 H 52.5 V 68 H 0 Z"
                  fill="none"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  strokeLinejoin="round"
                />
                {/* Center circle */}
                <circle
                  cx={x(0)}
                  cy={y(50)}
                  r={9.15}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  fill="none"
                />
                {/* Center spot */}
                <circle
                  cx={x(0)}
                  cy={y(50)}
                  r={strokeWidth}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  fill="none"
                />
                {/* penalty area */}
                <rect
                  x={x(34)}
                  y={y(81)}
                  width={x(16)}
                  height={y(19) - y(81)}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  strokeLinejoin="round"
                  fill="none"
                />
                {/* goal area */}
                <rect
                  x={x(44)}
                  y={y(62)}
                  width={x(6)}
                  height={y(38) - y(62)}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  strokeLinejoin="round"
                  fill="none"
                />
                {/*penalty spot */}
                <circle
                  cx={x(39)}
                  cy={y(50)}
                  r={strokeWidth}
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                  fill={strokeColor}
                />
                {/*penalty arc */}
                <path
                  d="M5.499,-7.313A9.15,9.15,0,0,1,5.499,7.313A9.15,9.15,0,0,0,5.499,-7.313Z"
                  transform="translate(41.25,34) rotate(180)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
                {/* Corner arcs */}
                <path
                  d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z"
                  transform="translate(52.5,0) rotate(180)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
                <path
                  d="M0,-1A1,1,0,0,1,1,0A1,1,0,0,0,0,-1Z"
                  transform="translate(52.4,68) rotate(275)"
                  stroke={strokeColor}
                  strokeWidth={strokeWidth}
                ></path>
              </g>
            </svg>
          </g>
        </svg>
      </g>
    </svg>
  );
}
