import React from "react";
import ReportLogo from "../shared/ReportLogo";
import {
  ReportContainer,
  LastPage,
  Page,
  PageHeader,
  PageContent,
} from "../shared/ReportLayout";
import { ReportContentContent, ReportPage } from "../models";
import TitlePage from "./components/TitlePage";
import StandardPage from "./components/StandardPage";
import GlossaryPage from "./components/GlossaryPage";
import PlayerSummaryPage from "./components/PlayerSummaryPage";
import { TableOfContent } from "../shared/TableOfContent";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";

export default function PlayerScoutingReport({
  content = { pages: [], total_pages: 100 },
  tableOfContent = false,
  loadingNextPage = false,
  onPageLoad,
}: {
  content: ReportContentContent;
  tableOfContent?: boolean;
  loadingNextPage?: boolean;
  onPageLoad: (pageNumber: number) => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const renderPage = (page: ReportPage, pageNumber: number) => {
    switch (page.type) {
      case "title_page":
        return <TitlePage page={page} />;
      case "basic_page":
        return (
          <PlayerSummaryPage
            page={page}
            pageNumber={pageNumber}
            competition={content.competition}
          />
        );
      case "standard_page":
        return <StandardPage page={page} pageNumber={pageNumber} onLoad={() => {
          onPageLoad(pageNumber);
        }} />;
      case "glossary_pages":
        return <GlossaryPage page={page} />;
      default:
        return <></>;
    }
  };

  return (
    <ReportContainer>
      {content.pages?.map((page, index) => (
        <React.Fragment key={`page-${index}`}>
          {renderPage(page, index)}
          {tableOfContent && index === 1 && !isMobile ? (
            <Page>
              <PageHeader
                title="Table of content"
                subtitle={!isMobile ? page.subheader : undefined}
              />
              <PageContent>
                <TableOfContent
                  pages={content.table_of_content?.map(
                    (p) => ({ header: p } as ReportPage),
                  )}
                  showPlaceholder={!isMobile}
                />
              </PageContent>
            </Page>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
      {content.pages?.length === content.total_pages ? (
        <LastPage>
          <ReportLogo size="large" />
        </LastPage>
      ) : (
        <Box sx={{ height: "100px" }}>{content.total_pages}</Box>
      )}
      {loadingNextPage ?
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px",
            boxSizing: "border-box",
            marginTop: "-60px",
          }}
        >
          <CircularProgress size="24px" />
        </Box> :
        <></>
      }
    </ReportContainer>
  );
}
