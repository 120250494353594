import { useDispatch } from "react-redux";
import { toggleSidebar } from "../store/viewReducer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as MenuIcon } from "../shared/icons/menu.svg";
import AppsIcon from "@mui/icons-material/Apps";
import {
  Box,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  Menu,
  MenuItem,
  styled,
  useTheme,
} from "@mui/material";
import { ReactComponent as Logo } from "../shared/icons/logo.svg";
import { Link } from "react-router-dom";
import React from "react";
import GradingIcon from "@mui/icons-material/Grading";
import { ReactComponent as TwelveLogo } from "../shared/icons/twelve-logo.svg";
import { useCurrentUser } from "../sections/auth/contexts/CurrentUserContext";
import { UserPermissions } from "../sections/auth/models";

const StyledTwelveLogo = styled(TwelveLogo)(({ theme }) => ({
  "& path": {
    fill: theme.palette.text.secondary,
  },
}));

const StyledListItemText = styled(ListItemText)<ListItemTextProps>(
  ({ theme }) => ({
    height: "38px",
    display: "flex",
    alignItems: "center",
  }),
);

export default function TopBar() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { currentUser } = useCurrentUser();
  const handleToggleSideBar = () => dispatch(toggleSidebar());
  const [appMenuAnchorEl, setappMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isAppMenuOpen = Boolean(appMenuAnchorEl);

  const handleAppMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setappMenuAnchorEl(event.currentTarget);
  };

  const handleAppMenuClose = () => {
    setappMenuAnchorEl(null);
  };

  return (
    <AppBar>
      <Toolbar
        variant="regular"
        sx={{
          order: 0,
          [theme.breakpoints.down("md")]: {
            justifyContent: "space-between",
          },
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            [theme.breakpoints.down("md")]: {
              flexGrow: 0,
            },
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleToggleSideBar}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        {currentUser?.chekPermissions([
          UserPermissions.READ_ALL_SCOUT_REPORTS,
          UserPermissions.READ_OWN_SCOUT_REPORTS,
          UserPermissions.CRAETE_SCOUT_REPORT,
          UserPermissions.CREATE_SCOUT_TEMPLATE,
        ]) && (
          <Box
            sx={{
              order: 1,
              [theme.breakpoints.down("md")]: {
                order: 3,
              },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="settings"
              onClick={handleAppMenuOpen}
            >
              <AppsIcon />
            </IconButton>
            <Menu
              anchorEl={appMenuAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              id={"app-menu"}
              keepMounted
              open={isAppMenuOpen}
              onClose={handleAppMenuClose}
              sx={{
                "& .MuiPaper-root": {
                  minWidth: "200px",
                  // elevation: 3,
                  background: theme.palette.backgrounds.menu,
                },
              }}
            >
              <MenuItem onClick={handleAppMenuClose} component={Link} to="/">
                <ListItemIcon>
                  <StyledTwelveLogo />
                </ListItemIcon>
                <StyledListItemText>Chat</StyledListItemText>
              </MenuItem>
              <MenuItem
                onClick={handleAppMenuClose}
                component={Link}
                to="/report"
              >
                <ListItemIcon>
                  <GradingIcon />
                </ListItemIcon>
                <StyledListItemText>Reports</StyledListItemText>
              </MenuItem>
            </Menu>
          </Box>
        )}
        <Box
          sx={{
            order: 2,
          }}
        >
          <Link to="/">
            <Logo width="92px" height="52px" style={{ marginLeft: "16px" }} />
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
