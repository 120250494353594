import {
  Box,
  BoxProps,
  useTheme,
  styled,
  Pagination,
  IconButton,
  useMediaQuery,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
//import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandableText from "./ExpandableText";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoadingText from "./LoadingText";

interface SwipeableContentProps {
  descriptions?: Array<string | undefined>;
  children: React.ReactNode;
  indicatorsVariant?: "circle" | "button";
  indicatorsStyle?: {
    activeColor?: string;
    disabledColor?: string;
  };
  textColor?: string;
  allowExpand?: boolean;
  minHeight?: string;
  useLoadingText?: boolean;
  onCurrentIndexChange?: (index: number) => void;
}

const IndicatorsBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "6px",
  height: "24px",
}));

const SwipeableContent: React.FC<SwipeableContentProps> = ({
  children,
  descriptions,
  indicatorsVariant = "circle",
  indicatorsStyle,
  allowExpand = true,
  textColor,
  minHeight = "unset",
  useLoadingText = false,
  onCurrentIndexChange,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userSelectValue, setUserSelectValue] = useState<"none" | "unset">(
    "unset",
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    onSwiping: () => setUserSelectValue("none"),
    onSwiped: () => setUserSelectValue("unset"),
    //trackMouse: true,
  });

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, childrenCount - 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleJump = (index: number) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    onCurrentIndexChange?.(currentIndex);
  }, [onCurrentIndexChange, currentIndex]);

  const childrenCount = React.Children.count(children);

  const activeColor =
    indicatorsStyle?.activeColor || theme.palette.action.active;
  const disabledColor =
    indicatorsStyle?.disabledColor || theme.palette.action.disabled;

  return (
    <Box
      sx={{
        width: "100%",
        userSelect: userSelectValue,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        {...handlers}
        sx={{
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          //minHeight: {minHeight}
        }}
      >
        {React.Children.map(children, (child, index) => (
          <Box
            key={index}
            sx={{
              left: `${(index - currentIndex) * 100}%`,
              position: `${index === currentIndex ? "relative" : "absolute"}`,
              top: 0,
              height: "100%",
              width: "100%",
              transition: "left 0.3s ease-in-out",
              overflow: "hidden",
            }}
          >
            {child}
          </Box>
        ))}
      </Box>
      {childrenCount > 1 &&
        (indicatorsVariant === "circle" ? (
          <IndicatorsBox sx={{ height: "34px" }}>
            {!isMobile ? (
              <IconButton
                size="small"
                disabled={currentIndex === 0}
                onClick={() => handleJump(currentIndex - 1)}
                sx={{ color: activeColor }}
              >
                <KeyboardArrowLeftIcon sx={{ height: "18px", width: "18px" }} />
              </IconButton>
            ) : (
              <></>
            )}
            {React.Children.map(children, (_, index) => (
              <Box
                key={`indicator-${index}`}
                onClick={() => handleJump(index)}
                sx={{
                  cursor: "pointer",
                  width: currentIndex === index ? "16px" : "4px",
                  height: "4px",
                  borderRadius: "4px",
                  background:
                    currentIndex === index ? activeColor : disabledColor,
                }}
              />
            ))}
            {!isMobile ? (
              <IconButton
                size="small"
                disabled={currentIndex === childrenCount - 1}
                onClick={() => handleJump(currentIndex + 1)}
                sx={{ color: activeColor }}
              >
                <KeyboardArrowRightIcon
                  sx={{ height: "18px", width: "18px" }}
                />
              </IconButton>
            ) : (
              <></>
            )}
          </IndicatorsBox>
        ) : (
          <IndicatorsBox sx={{ height: "80px" }}>
            <Pagination
              count={childrenCount}
              page={currentIndex + 1}
              onChange={(event, value) => setCurrentIndex(value - 1)}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: alpha(
                    theme.palette.customColors.text,
                    theme.palette.customColors.opacity.text.primary,
                  ),
                },
                "& .MuiPaginationItem-page.Mui-selected": {
                  color: alpha(
                    theme.palette.customColors.text,
                    theme.palette.customColors.opacity.text.secondary,
                  ),
                  background: theme.palette.backgrounds.tertiary,
                },
              }}
            />
          </IndicatorsBox>
        ))}
      {descriptions?.length ? (
        <Box
          sx={{
            fontSize: "16px",
            marginTop: "16px",
            width: "100%",
          }}
        >
          {/* <InfoOutlinedIcon/> */}
          {descriptions[currentIndex] ? (
            <ExpandableText
              text={descriptions[currentIndex]}
              allowExpand={allowExpand}
              color={textColor}
              useLoadingText={useLoadingText}
            />
          ) : (
            <LoadingText />
          )}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SwipeableContent;
