import React, { useEffect, useRef, useState } from "react";
import { api } from "../../../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import useDebounce from "../../../../shared/hooks/useDebounce";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import {
  NoSearchResults,
  SearchBox,
  SearchBoxContainer,
  SearchDefaultText,
  SearchInput,
  SearchList,
  SearchListItemButton,
  SearchListItemTextMain,
  SearchListItemTextSecondary,
  SearchProgress,
} from "./SearchLayoutComponents";
import { SearchTeamInfo } from "../../../../models";

interface SearchTeamProps {
  open: boolean;
  onClose: () => void;
  onSelect: (team: SearchTeamInfo) => void;
}

const SearchTeamModal: React.FC<SearchTeamProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [teams, setTeams] = useState<SearchTeamInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const debouncedSearchTerm = useDebounce(searchQuery, 600);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setSearchQuery("");
    setTeams([]);
  }, [open]);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const search = async () => {
      setLoading(true);
      try {
        const result = await api.searchTeams(
          await getAccessTokenSilently(),
          debouncedSearchTerm,
          controller.signal,
        );
        setTeams(result);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    if (debouncedSearchTerm?.length < 3) {
      if (!debouncedSearchTerm.length) {
        setTeams([]);
      }
      return;
    }

    search();

    return () => {
      controller.abort();
    };
  }, [getAccessTokenSilently, debouncedSearchTerm]);

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      position="top"
    >
      <SearchBoxContainer>
        <SearchInput
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <SearchBox>
          {loading ? (
            <SearchProgress />
          ) : debouncedSearchTerm.length ? (
            teams.length > 0 ? (
              <SearchList dense className="scrollable">
                {teams.map((team, index) => (
                  <SearchListItemButton
                    key={`team-${index}`}
                    onClick={() => {
                      onSelect(team);
                      onClose();
                    }}
                  >
                    <SearchListItemTextSecondary
                      secondary={
                        (team.is_national_team
                          ? "National team"
                          : team.country) +
                        (team.gender === "female" ? " | Womens" : " | Mens")
                      }
                    />
                    <SearchListItemTextMain primary={team.team} />
                  </SearchListItemButton>
                ))}
              </SearchList>
            ) : (
              <NoSearchResults text="No teams found" />
            )
          ) : (
            <SearchDefaultText text="Find teams by their name." />
          )}
        </SearchBox>
      </SearchBoxContainer>
    </ResponsiveDialog>
  );
};

export default SearchTeamModal;
