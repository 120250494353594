import React from "react";
import {
  Dialog,
  useMediaQuery,
  useTheme,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import BottomSwipeableDrawer from "./BottomSwipeableDrawer";

interface ResponsiveDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  itemId?: string | number;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  position?: "top" | "center";
}

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
  open,
  onClose,
  title,
  children,
  actions,
  maxWidth = "xs",
  position = "center",
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const positionStyle =
    position === "top" ? { top: "10vh", position: "absolute" } : {};

  return (
    <>
      {isMobile ? (
        <BottomSwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
        >
          <div role="presentation">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
                width: "100%",
                alignItems: "stretch",
                gap: "16px",
                paddingBottom: "16px",
              }}
            >
              {actions}
            </DialogActions>
          </div>
        </BottomSwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth
          maxWidth={maxWidth}
          PaperProps={{
            elevation: 1,
            sx: {
              borderRadius: "16px",
              padding: "24px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              ...positionStyle,
            },
          }}
          slotProps={{
            root: {
              style: {
                zIndex: 9999,
              },
            },
            backdrop: {
              sx: {
                backdropFilter: "blur(3px)",
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            },
          }}
        >
          <DialogTitle sx={{ padding: 0 }}>{title}</DialogTitle>
          <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
          <DialogActions sx={{ padding: 0 }}>{actions}</DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ResponsiveDialog;
