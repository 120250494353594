import React, { useEffect, useRef, useState } from "react";
import { api } from "../../../../api/twelve";
import { useAuth0 } from "@auth0/auth0-react";
import useDebounce from "../../../../shared/hooks/useDebounce";
import ResponsiveDialog from "../../../../shared/components/ResponsiveDialog";
import {
  NoSearchResults,
  SearchBox,
  SearchBoxContainer,
  SearchDefaultText,
  SearchInput,
  SearchList,
  SearchListItemButton,
  SearchListItemTextMain,
  SearchListItemTextSecondary,
  SearchProgress,
} from "./SearchLayoutComponents";
import {
  SearchMatchInfo,
  SearchMatchesForTeamResult,
  SearchMatchesResult,
} from "../../../../models";
import { Box, IconButton, ListSubheader } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DateTimeHelper from "../../../../shared/helpers/datetime.helper";

interface SearchMatchProps {
  open: boolean;
  onClose: () => void;
  onSelect: (match: SearchMatchInfo) => void;
}

const SearchMatchModal: React.FC<SearchMatchProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [matches, setMatches] = useState<SearchMatchesResult>({ matches: {} });
  const [selectedTeam, setSelectedTeam] = useState<
    { id: number; name: string; gender?: string } | undefined
  >();
  const [matchesForTeam, setMatchesForTeam] = useState<
    SearchMatchesForTeamResult | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const debouncedSearchTerm = useDebounce(searchQuery, 600);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    setSearchQuery("");
    setMatches({ matches: {} });
    setMatchesForTeam(undefined);
    setSelectedTeam(undefined);
  }, [open]);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const search = async () => {
      setLoading(true);
      try {
        setSelectedTeam(undefined);
        const result = await api.searchMatches(
          await getAccessTokenSilently(),
          debouncedSearchTerm,
          controller.signal,
        );
        if (result.matches && Object.keys(result.matches).length === 1) {
          const teamId = Object.keys(result.matches)[0];
          const [, { team_name, gender }] = Object.entries(result.matches)[0];
          if (teamId) {
            setSelectedTeam({
              id: Number(teamId),
              name: team_name,
              gender: gender,
            });
            return;
          }
        }
        setMatches(result);
      } catch {}
    };

    if (debouncedSearchTerm?.length < 3) {
      if (!debouncedSearchTerm.length) {
        setMatches({ matches: {} });
        setMatchesForTeam(undefined);
        setSelectedTeam(undefined);
      }
      return;
    }

    search();

    return () => {
      controller.abort();
    };
  }, [getAccessTokenSilently, debouncedSearchTerm]);

  useEffect(() => {
    setLoading(false);
  }, [matches]);

  useEffect(() => {
    if (!selectedTeam) {
      setMatchesForTeam({ future_matches: [], past_matches: [] });
      return;
    }

    const search = async () => {
      setLoading(true);
      try {
        const matchesForTeam = await api.searchMatchesForTeam(
          await getAccessTokenSilently(),
          selectedTeam.id,
        );
        setMatchesForTeam(matchesForTeam);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    search();
  }, [getAccessTokenSilently, selectedTeam]);

  return (
    <ResponsiveDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      position="top"
    >
      <SearchBoxContainer>
        <SearchInput
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        <SearchBox>
          {loading ? (
            <SearchProgress />
          ) : debouncedSearchTerm.length ? (
            Object.keys(matches.matches).length > 0 || selectedTeam ? (
              <SearchList
                dense
                className="scrollable"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: selectedTeam ? 0 : "18px",
                }}
              >
                {selectedTeam ? (
                  <>
                    <ListSubheader
                      disableSticky
                      sx={{
                        background: "transparent",
                        display: "flex",
                      }}
                    >
                      <SearchListItemTextSecondary
                        secondary={
                          selectedTeam.name +
                          (selectedTeam.gender === "male"
                            ? " | Mens"
                            : selectedTeam.gender === "female"
                            ? " | Womens"
                            : "")
                        }
                      />
                    </ListSubheader>
                    {[
                      ...(matchesForTeam?.future_matches || []).sort((a, b) =>
                        new Date(a.scheduled_start) <
                        new Date(b.scheduled_start)
                          ? 1
                          : -1,
                      ),
                      ...(matchesForTeam?.past_matches || []),
                    ].map((match, index) => (
                      <SearchListItemButton
                        key={`future-match-${index}`}
                        onClick={() => {
                          onSelect(match);
                          onClose();
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <SearchListItemTextMain
                            primary={`${match.home_team_name}${
                              match.home_team_score != null
                                ? ` ${match.home_team_score}`
                                : ""
                            } - ${
                              match.away_team_score != null
                                ? `${match.away_team_score} `
                                : ""
                            }${match.away_team_name}`}
                          />
                          <SearchListItemTextSecondary
                            sx={{ textAlign: "right" }}
                            secondary={`${DateTimeHelper.format(
                              match.scheduled_start,
                              "D/M-YYYY",
                            )}`}
                          />
                        </Box>
                      </SearchListItemButton>
                    ))}
                  </>
                ) : (
                  Object.entries(matches.matches).map(
                    (
                      [key, { team_name, gender, next_match, previous_match }],
                      index,
                    ) => (
                      <Box key={`match-${index}`}>
                        <ListSubheader
                          disableSticky
                          sx={{
                            background: "transparent",
                            display: "flex",
                          }}
                        >
                          <SearchListItemTextSecondary
                            secondary={
                              team_name +
                              (gender === "male"
                                ? " | Mens"
                                : gender === "female"
                                ? " | Womens"
                                : "")
                            }
                          />
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSelectedTeam({
                                id: Number(key),
                                name: team_name,
                                gender: gender,
                              });
                            }}
                          >
                            <ArrowOutwardIcon
                              fontSize="small"
                              sx={{
                                transform: "rotate(-90deg)",
                              }}
                            />
                          </IconButton>
                        </ListSubheader>
                        {next_match && (
                          <SearchListItemButton
                            onClick={() => {
                              onSelect(next_match);
                              onClose();
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <SearchListItemTextMain
                                primary={`${next_match.home_team_name} - ${next_match.away_team_name}`}
                              />
                              <SearchListItemTextSecondary
                                sx={{ textAlign: "right" }}
                                secondary={`${DateTimeHelper.format(
                                  next_match.scheduled_start,
                                  "D/M-YYYY",
                                )}`}
                              />
                            </Box>
                          </SearchListItemButton>
                        )}
                        {previous_match && (
                          <SearchListItemButton
                            onClick={() => {
                              onSelect(previous_match);
                              onClose();
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <SearchListItemTextMain
                                primary={`${previous_match.home_team_name} ${previous_match.home_team_score} - ${previous_match.away_team_score} ${previous_match.away_team_name}`}
                              />
                              <SearchListItemTextSecondary
                                sx={{ textAlign: "right" }}
                                secondary={`${DateTimeHelper.format(
                                  previous_match.scheduled_start,
                                  "D/M-YYYY",
                                )}`}
                              />
                            </Box>
                          </SearchListItemButton>
                        )}
                      </Box>
                    ),
                  )
                )}
              </SearchList>
            ) : (
              <NoSearchResults text="No matches found" />
            )
          ) : (
            <SearchDefaultText text="Find matches by searching for home and/or away team." />
          )}
        </SearchBox>
      </SearchBoxContainer>
    </ResponsiveDialog>
  );
};

export default SearchMatchModal;
